/* eslint-disable max-len */
import image1 from "assets/images/size-guide-1.png";
import image2 from "assets/images/size-guide-2.png";
import image3 from "assets/images/size-guide-3.png";
import image4 from "assets/images/size-guide-4.png";
import image5 from "assets/images/size-guide-5.png";
import image6 from "assets/images/size-guide-6.png";

const SIZE_GUIDE_DATA = [
	{
		mediumHeading: "Storage Unit Size Guide",
		description:
			"An important factor when searching for a storage unit is finding the exact unit size you need. There’s a wide variety of storage unit sizes at most facilities, ranging from the size of a closet all the way to the size of an extended garage. It’s important to get the right unit size because it means you’re not wasting money on space you don’t actually need."
	},
	{
		mediumHeading: "3 Things to think about when renting a storage unit",
		subHeadings: [
			{
				title: "1. Start by taking inventory",
				desc: "Before you even begin to weigh your unit size options available to you, take inventory of everything you want to put into storage. Get out the measuring tape for some of your larger items to determine the smallest space they can fit into. Once you know everything that you’re going to store, you can use the size guide to figure out what unit size you need."
			},
			{
				title: "2. Standard unit sizes",
				desc: "Let’s take an in-depth look at the standard unit sizes you can find at most facilities and what they’re typically used for. Below are each of these unit sizes (which usually have a height of eight feet) with descriptions of items that can be contained in them and common uses."
			},
			{
				customCardsData: true
			},
			{
				title: "3. Other size factors to consider",
				desc: "Always be sure to consider the limitations of your entire storage space, and make sure you take advantage of all three dimensions when thinking about your items (i.e., length, width, and height). Here are some other things to think about:"
			}
		],
		points: [
			{
				desc: "Be aware that many household items come in different sizes. In other words, just because a storage unit can fit a small mattress doesn’t necessarily mean it can fit your king size mattress."
			},
			{
				desc: "Items like sofas or dressers may have to be turned on their side in order to maximize your storage space. Use the eight-foot vertical space to your advantage."
			},
			{
				desc: "Including shelves can help you take advantage of the height of your storage unit and store more items."
			}
		],
		description2:
			"Once you’ve figured out the size you’re looking for, you can narrow down your facility results and only view the sizes you’re interested in. Finding a storage unit has never been this easy!"
	},
	{
		mediumHeading: "Storage Unit Size FAQs",
		subHeadings: [
			{
				title: "How tall is a standard storage unit?",
				desc: "While storage unit heights at each facility will vary, the standard height of a storage unit tends to be around 8 feet tall. 10 feet and higher are available at select facilities."
			},
			{
				title: "What if I choose the wrong size?",
				desc: "This isn't all that uncommon. It can be hard to visualize how much space you need. If you have secured a storage unit that is inaccurately matched with what you plan to store, the facility manager can help find you the right storage unit size. Not only will they help you make the necessary changes in your rental agreement, but they will also assist you in determining the proper unit size suitable for your belongings."
			},
			{
				title: "How are storage units priced differently based on size?",
				desc: "The price you can expect to pay for each square foot of storage space is different at every facility, and is determined by your location, choice of unit features, and a number of other factors."
			},
			{
				title: "Do all storage unit sizes have the same amenities?",
				desc: "Smaller storage units can be interior or exterior. Smaller storage units are typically interior with options for climate control, while larger units are almost always exterior with drive-up access (climate control isn’t very common with exterior storage units). Otherwise, security features are typically the same for units across the board at a given facility."
			}
		]
	},
	{
		mediumHeading: "Popular Cities For Self Storage",
		popularCities: true
	}
];

const CUSTOM_CARDS_DATA = [
	{
		image: image1,
		heading: "5 x 5 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Dresser, small mattress set, and several boxes"
			},
			{
				title: "Common Uses"
			}
		],
		points: [
			"Additional storage space for those who live in small, downtown apartments",
			"Storing off-season clothing and accessories",
			"Storing seasonal decorations, lawn and gardening equipment, or sporting gear"
		]
	},
	{
		image: image2,
		heading: "5 x 10 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Queen-sized mattress set, dresser, TV, and several boxes (could fit a smaller loveseat or other small furniture)"
			},
			{
				title: "Common Uses"
			}
		],
		points: [
			"Storing dorm furniture and belongings over the summer",
			"Storing sporting gear, such as surfboards, snowboards, or golf clubs",
			"Temporary storage while moving from one small apartment to another"
		]
	},
	{
		image: image3,
		heading: "10 x 10 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Entire family room (sofa, coffee table, chairs, bookshelves, etc.) and two full bedrooms (mattress sets, night stands, dressers, etc.)"
			},
			{ title: "Common Uses" }
		],
		points: [
			"Storing large furniture and appliances during a move",
			"Storing a jet ski or motorcycle",
			"Storing tailgating gear, like a small trailer, during the off-season"
		]
	},
	{
		image: image4,
		heading: "10 x 15 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Three full bedrooms, plus larger furniture (sofas, chairs, tables, appliances, etc.)"
			},
			{ title: "Common Uses" }
		],
		points: [
			"Storing the contents of a large apartment or house while in the process of moving",
			"Storing a compact vehicle, motorcycle, scooter, or jet ski"
		]
	},
	{
		image: image5,
		heading: "10 x 20 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Entire multiple-bedroom house, including large furniture (sofas, chairs, tables, appliances, etc.) and several boxes"
			},
			{
				title: "Common Uses"
			}
		],
		points: [
			"Storing the contents of a small house while moving or building a new home",
			"Storing cars and other vehicles",
			"Additional business storage for supplies or equipment"
		]
	},
	{
		image: image6,
		heading: "10 x 30 Storage Units",
		subHeadings: [
			{
				title: "What Can Fit",
				desc: "Entire multiple-bedroom house, including large furniture (sofas, chairs, tables, appliances, etc.) and several boxes"
			},
			{
				title: "Common Uses"
			}
		],
		points: [
			"Storing the contents of an entire home while moving from one house to the next",
			"Indoor vehicle storage with space left over for more belongings",
			"Business storage for inventory or large equipment"
		]
	}
];

export { SIZE_GUIDE_DATA, CUSTOM_CARDS_DATA };
