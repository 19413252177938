/* eslint-disable react/self-closing-comp */
import React from "react";
import SearchBar from "components/search/SearchBar/SearchBar";
import { H4 } from "components/core/Typography";

function LastSearchBar() {
	return (
		<>
			<div className="margin-top"></div>
			<SearchBar
				className="last-searchbar"
				buttonText="Find Units"
				id="hero-search-bar2"
				label="Search by location"
				theme="lightBlue2"
				segmentCategory="Hero-SearchBar"
			>
				<H4
					weight="bold"
					size="large"
					largeSize="large"
					align="center"
					color="black"
				>
					Find Storage Units Near You
				</H4>
			</SearchBar>
		</>
	);
}

export default LastSearchBar;
