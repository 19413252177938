import React from "react";
import { Link } from "components/core/Typography";
import PropTypes from "prop-types";

function PopularCities({ cityLinks }) {
	return (
		<div className="all-popular-cities">
			{cityLinks.map((city) => (
				<span key={city.name}>
					<Link
						hover
						size="standard"
						color="blue"
						weight="bold"
						underline
						href={city.link}
					>
						{city.name}
					</Link>
				</span>
			))}
		</div>
	);
}

PopularCities.propTypes = {
	cityLinks: PropTypes.arrayOf(PropTypes.object)
};

export default PopularCities;
