import React from "react";
import { getHeadingByLevel } from "components/core/Typography";
import PropTypes from "prop-types";

const SubHeading = ({ title, level = "2", size="xlarge" }) => {
	const HeadingTag = getHeadingByLevel(level);

	if (!title) return null;

	return (
		<HeadingTag size={size}>
			{title}
		</HeadingTag>
	);
};

SubHeading.propTypes = {
	title: PropTypes.string,
	level: PropTypes.string,
	size: PropTypes.string
};

export default SubHeading;
