import React from "react";
import { ListItem, Text } from "components/core/Typography";
import PropTypes from "prop-types";

const ListPoint = ({ title, desc, bulletPoints = true }) => {
	const customClass = bulletPoints ? "bullet-point" : "no-bullet-point";
	return (
		<ListItem>
				<div className={customClass}>
				     {title && <Text weight="bold">{title} </Text>}
				     {desc && <Text>{desc}</Text>}
		        </div>      
		</ListItem>
	);
};

ListPoint.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	bulletPoints: PropTypes.bool,
};

export default ListPoint;
