import React from "react";
import { H2, H3, Paragraph } from "components/core/Typography";
import PropTypes from "prop-types";
import { Image } from "@sparefoot/react-commons";
import { CUSTOM_CARDS_DATA } from "components/storages/ContentData/SizeGuideData";

const CustomSubHeading = ({ title, desc }) => (
	<>
		<H3>{title}</H3>
		<Paragraph>{desc}</Paragraph>
	</>
);

function SizeGuideCards() {
	return (
		<>
			<div className="border-bottom" />
			{CUSTOM_CARDS_DATA.map((item) => (
				<>
					<div className="size-guide-card">
						<div className="image-container">
							<Image
								src={item.image}
								alt="image1"
								height="188"
								width="219"
							/>
						</div>
						<div className="content-container">
							<H2>{item.heading}</H2>
							{item?.subHeadings?.map((subHeading) => (
								<CustomSubHeading {...subHeading} />
							))}
							{item.points && (
								<ul>
									{item.points?.map((point) => (
										<>
											<li>
												{point} 
												<br />
											</li>
										</>
									))}
								</ul>
							)}
						</div>
					</div>
					<div className="border-bottom" />
				</>
			))}
		</>
	);
}

CustomSubHeading.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string
};

export default SizeGuideCards;
