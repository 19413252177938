import React from "react";
import { Link } from "components/core/Typography";
import PropTypes from "prop-types";

const CustomHeading = ({ content }) => (
	<Link
		href="/"
		color="blue"
		size="xxlarge"
		weight="bold"
	>
		{content}
	</Link>
);

CustomHeading.propTypes = {
	content: PropTypes.string
};

export default CustomHeading;
