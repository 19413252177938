/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Paragraph, Link } from "components/core/Typography";
import PropTypes from "prop-types";

const Description = ({ desc, descLink, descLinkMultiple }) => (
	<>
		{desc && <Paragraph>{desc}</Paragraph>}
		{descLink && (
			<Paragraph>
				{descLink.contentBefore}
				<Link
					hover
					size="standard"
					color="blue"
					underline
					href={
						descLink.link
							? descLink.link
							: `tel:${descLink?.phone?.number}`
					}
				>
					{descLink.linkText}
				</Link>
				{descLink.contentAfter}
			</Paragraph>
		)}

		{descLinkMultiple?.map((item) => (
			<span style={{ lineHeight: "25px" }}>
				{item.content}
				<Link
					hover
					size="standard"
					color="blue"
					href={
						item.phone
							? `tel:${item?.phone?.number}`
							: item.email
							? `mailto:${item.email}`
							: item.link
					}
				>
					{item.linkText}
				</Link>
			</span>
		))}
	</>
);

Description.propTypes = {
	desc: PropTypes.string,
	descLink: PropTypes.object,
	descLinkMultiple: PropTypes.array
};

export default Description;
