import React from "react";
import { getHeadingByLevel } from "components/core/Typography";
import PropTypes from "prop-types";

const Heading = ({
	content,
	color = "",
	level = "1",
	size = "huge",
	weight = "bold",
	className = ""
}) => {
	const HeadingTag = getHeadingByLevel(level);

	if (!content) return null;

	return (
		<HeadingTag
			color={color}
			className={className}
			weight={weight}
			size={size}
		>
			{content}
		</HeadingTag>
	);
};

Heading.propTypes = {
	content: PropTypes.string,
	level: PropTypes.string,
	size: PropTypes.string,
	weight: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string
};

export default Heading;
